import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const baseUrl = '/data/images/'

class TextPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    handleClose = ()=>{ 
        this.props.history.push('/lobby');
    }

    render() {   
        var settings = {
            dots: true
        };

        console.log(this.props.images)

        return (
            <div className="container">
            <div className="artwork-container-close" onClick={this.handleClose}>Close</div>
            <h1>{ this.props.name }</h1>
            <div className="text-captions" dangerouslySetInnerHTML={{ __html:this.props.description}} />
            </div>
        );
    }
}


export default TextPage
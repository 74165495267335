import React , { Suspense }from 'react'
import { Link } from "react-router-dom"
// const Status = React.lazy(() => import('./Status'));
import Close from './CloseButton'


class About extends React.Component {
  
    componentDidMount () {
    }
  
    componentWillUnmount () {

    }
  
 
    render () {
      return (
        <div className="about">
          <Close />
          <div className="about-col">
             <h1>ELEPHANT IN THE ROOM </h1>
              <div>
              2nd part of Playbour City Engines <br/>
              <i>in collaboration with <a href="https://schoolofspeculation.xyz/">School of Speculation, London</a></i><br/>
              <p className="emphase">24 September 2020 7pm CEST</p> 
              <p><span className="dot">●</span> LIVE</p>
              <br/>
              <p>School of Speculation, London (SOS) is an independent and nomadic critical design school that aims to challenge current models of delivering higher education. Through partnerships between museums, galleries, libraries and other public institutions, SOS aims to build relationships between the diverse latent pedagogy in our cities to create a different kind of educational offer. </p>
              </div>
              <div>
              <br/>
              NEXT <br/> <br/>
              CHAPTER III.<br/>
              Transforming Desires with Handi Kim<br/>
              <p><span className="dot">●</span> LIVE</p> 3 December 2020 7pm CEST<br/>
              <a className="about-link" href="https://desires.tetem.nl/">Visit at desires.tetem.nl</a>
              </div>
              <div>
              CHAPTER IV.<br/>
              Surprise and Adventure: When Reality Becomes Like a Game<br/>
              23 February 2021 7:30pm CEST<br/>
              <a className="about-link" href="https://us02web.zoom.us/j/84598726131">Zoom Link</a>
              </div>
              <div>
              <br/>
                PREVIOUS <br/> <br/>
                CHAPTER I.<br/>
                Transaction Party<br/>
                <i>with Yessica Deira</i><br/>
                <p><span className="dot">●</span> LIVE</p> 17 June 2020 8pm CEST <br/>
                <a href="https://transactionparty.tetem.nl/">transactionparty.tetem.nl</a>
              </div>
        </div>
         <div className="about-col logn-text">
            <p>Welcome to the virtual exhibition Elephant in the Room, the second chapter of Playbour City Engines. </p> 
            <p>Elephant in the Room is inspired by the transformation of London’s old Elephant and Castle Shopping Centre, which was a place of hope for many, from its origins in 1965 as one of the UK’s first American-style shopping malls turning into the centre-piece of a thriving minority community in central London. It has defied years of commercial pressure to bend to the will of corporate merchandising, and lives on here as a statement of how things can be done differently. Demolition of the Elephant and Castle Shopping Centre began on July 30th 2020. Unable to resist the pressure from local government and big business, the shopping centre fell to larger redevelopment plans.</p>
            <p>School of Speculation and The Rodina are co-opting this space of critical urbanism to enlighten its own cause toward an alternative to higher education. Visitors are welcome to enter a virtual online show in a huge shopping mall that reflects today’s education challenges. Students can no longer afford extortionate fees paired with a move towards the delivery of capitalised consumer-style education. A crisis in the Arts looms as art, design and architecture courses increasingly pander to industry and governments lobbying for vocational and skills-based courses. As SOS looks to dismantle this toxic paradigm, perhaps there are lessons to be learnt from the Elephant’s successes?</p>
            <p>SOS is an independent and nomadic critical design school that aims to challenge current models of delivering higher education. Through partnerships between museums, galleries, libraries and other public institutions, SOS aims to build relationships between the diverse latent pedagogy in our cities to create a different kind of educational offer. </p>
            <p>This year’s SOS design residency culminates in the virtual delights of a reimagined Elephant and Castle Shopping Centre, repurposed for the aim of exhibiting the work of 12 participants of wide-ranging backgrounds and disciplines. Resident’s work ranges from digital activism to new work rituals for the new office, from the vital archiving of changing social landscapes to questioning the role of the hoax in state conspiracies. </p>
            <p>SOS 2020 Residents: Anon 1, Anon 2, Andrew Copolov, Jonathan Pilosof, Louis Scantlebury, Okocha Obasi, Oniqur Rahman, Peter Brooks, Rebecca Liu, Rosemary Moss, Roxy Zeiher, Sharvaree Shirode </p>
            <p>We would like to express our thanks to Ella Buzo, 	Wilja Jurg, The Koppel Project, South London Gallery, Design Museum London, Tetem, Pierre Shaw, Kishan San, Yessica Deira, Yochanna Didi, Ventolin, Lukas Likavcan, Gideon Kiers, Annie Goodner, and many others.</p>
            <p>Playbour City Engines was commissioned by Tetem.</p>
            <p>Supported by Mondriaan Fonds, Stimuleringsfonds, Gemeente Enschede and Provincie Overijssel.</p>
          </div>
        </div>

      )
    }
  }
  
  
  export default About
import React from 'react'
import GeoChat from './geochat/geochat'
import {
  Link,
} from "react-router-dom"

class LandingPage extends React.Component {
  
    componentDidMount () {
    }
  
    componentWillUnmount () {

    }
  
 
    render () {
      return (
          <div className="splashScreen">
             <div className="geochat">
                <GeoChat/>
            </div>
            <div className="splashScreen-info">
              In order to maintain a space in which all individuals feel safe and welcome, visitors are required to sign in on entry. Your email address will not be made public.
              This website stores data such as cookies to enable important site functionality including analytics, targeting, and personalisation. <a href='./privacy'>Data storage policy</a>.
            </div>
            <div className="splashScreen-name">
              sos.therodina.com
            </div>
    
            <div className="splashScreen-image">
               <img src="./images/splashscreen.jpg"/>
            </div>
          </div>
      )
    }
  }
  
  
  export default LandingPage
import React , { Suspense }from 'react'
import { Link } from "react-router-dom"
// const Status = React.lazy(() => import('./Status'));


class Header extends React.Component {
  
    componentDidMount () {
    }
  
    componentWillUnmount () {

    }
  
 
    render () {
      return (
        <div>
          <div className="header">
            <div className="nav-button" to="/lobby">{this.props.score}</div>                   
          </div>
          <div className="header-mute" onClick={this.props.handleMute}>
               { this.props.volume === 0 ? 'Unmute' : 'Mute' }
          </div>
          {/* <div className="footer-right" onClick={this.props.handleMute}>
            { this.props.volume ? 'Mute': 'Unmute'}
          </div>  */}
          <div className="footer-left">
            {/* <Suspense fallback={<div>Loading...</div>}> 
              <Status updateStatus={this.props.updateStatus}/>
            </Suspense> */}
          </div> 
        </div>

      )
    }
  }
  
  
  export default Header
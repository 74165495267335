import React, {Suspense} from 'react'
import Loading from '../Loading'
import Instructions from '../Instructions'

const BabylonScene = React.lazy(() => import('../BabylonScene.jsx'))

const Booth = function(props) {
    return (
        <Suspense fallback={<Loading />}>
            <BabylonScene
                getVolume={props.getVolume}
                firebase={props.firebase}
                sceneFile="speculation_2.babylon"
                roomName="Tetem"
                user={props.user}
                history={props.history}
                handleInstructions={props.handleInstructions}
                handleScore={props.handleScore}

            />
            { props.displayInstructions && <Instructions /> }
        </Suspense>
    )
}


export default Booth



import React from 'react'
import Vimeo from '@u-wave/react-vimeo';

class VideoPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    handleClose = ()=>{ 
        this.props.history.push('/lobby');
    }

    render() {         
        return (
            <div className="artwork-container">
                <div className="artwork-container-close" onClick={this.handleClose}>Close</div>
                <h1>{ this.props.name }</h1>
                <div className="video-container">
                    <div className="video-container-after" style={{ paddingBottom: '56.25%'}}>                               
                    </div>
                    <div className="video-container-content">
                        <Vimeo
                            video={this.props.video + ''}
                            autoplay
                            className="artwork-videoplayer"
                            // controls={false}
                        />
                    </div>
                </div>
                <div className="artwork-caption" dangerouslySetInnerHTML={{ __html:this.props.description}}/>
            </div>
        )
    }
}


export default VideoPage 

// import React from 'react'
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from 'axios'; 
import yaml from 'js-yaml';

import VideoPage from './pages/VideoPage'
import Gallery from './pages/Gallery'
import TextPage from './pages/TextPage'
import Loading from './Loading'


const baseUrl = '/data/'

function Page(props) {
    let { id } = useParams();
    const [data, setData] = useState('');
    const [state, setState] = useState('loading');

    useEffect(() => {
        const fetchData = async () => {
          const result = await axios(
            baseUrl + id + '.yml',
          );
        //    console.log(baseUrl + id) 
        //    console.log(result.data) 
           const jsonPage = yaml.load(result.data)
          //  console.log(jsonPage)
            setData(jsonPage);
            // setState(jsonPage.type)
        };
     
        fetchData();
      }, []);
    console.log(data);    

    if (data.type ===  'video') {
      return (
        <div className="details">
          <VideoPage history={props.history} description={data.description} video={data.video} name={data.name}/>
        </div>
      );
    }

    if (data.type ===  'text') {
      return (
        <div className="details">
          <TextPage history={props.history} description={data.description} video={data.video} name={data.name}/>
        </div>
      );
    }

    if (data.type ===  'gallery') {
      return (
        <div className="details">
          <Gallery history={props.history} description={data.description} credits={data.credits} images={data.images} name={data.name}/>
        </div>
      );
    }         

    return (
      <Loading />
      );  
}

export default Page


import React from 'react'
import FileUploader from "react-firebase-file-uploader";
import 'regenerator-runtime/runtime'

class Profile extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isUploading: false,
            isProcessing: false,
            progress: 0,
            avatarURL: this.props.user.photoURL,
            name: this.props.user.displayName,
            canSave: false
        }
    }

    // handleChangeUsername = event =>
    // this.setState({ username: event.target.value });
    handleUploadStart = () => {
        this.setState({ 
            isUploading: true, 
            progress: 0,
            canSave: false
        })
    }
    handleProgress = progress => {
            this.setState({ progress })
    };

    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };

    handleUploadSuccess = async (filename) => {
        this.setState({ 
            progress: 0,
            isUploading: false,
            isProcessing: true
        })

        const file = 'thumb_' + filename
        // const profileImage = 'https://firebasestorage.googleapis.com/v0/b/progress-bar-47454.appspot.com/o/' + file + '?alt=media'
        // const profileImage = 'https://firebasestorage.googleapis.com/v0/b/tetem-playbour.appspot.com/o/' + file + '?alt=media'
        const profileImage = 'https://firebasestorage.googleapis.com/v0/b/sos-elephant.appspot.com/o/' + file + '?alt=media'

        const storageRef = this.props.firebase.storage().ref().child(file);

        // this.setState({ avatarURL: profileImage, progress: 100, isUploading: false })
        keepTrying(30, storageRef).then((url) =>{ 
            this.setState({ 
                avatarURL: profileImage, 
                progress: 0, 
                isUploading: false,
                canSave: true,
                isProcessing: false
            })
        });
    }

    handleChange = (e) => {
        this.setState({
            name: e.target.value,
            canSave: !this.state.isUploading
        });
    }

    handleOnSave = () => {
        if (this.state.canSave) {
            this.props.user.updateProfile({
                displayName: this.state.name,
                photoURL: this.state.avatarURL,
            }).then(()=>{
                this.props.handleUserUpdate()
                this.setState({
                    canSave: false
                })
            })
        }
    }

    handleOnCancel = () => {
        console.log(this.props.history)
        this.props.history.back()
    }

    handleSubmit = (e) => {
        e.preventDefault()
    }


    render() {               
        return (
            <div className="profile-container">
                {/* <div>{this.props.user && <span> {this.props.user.displayName}</span>}</div> */}

                <form onSubmit={this.handleSubmit}>
                    <label>Profile Name</label>
                    <div>
                        <input type="text" value={this.state.name} onChange={this.handleChange} />
                    </div>
                </form>

                <div className="profile-image-container">
                    <div>Profile Image</div>
                    {/* <div className="header-avatar">
                        <img src={this.props.user.photoURL} / >
                    </div>  */}
                    {this.state.isUploading && <div>Uploading {this.state.progress}%</div>}
                    {this.state.isProcessing && <div>Processing image </div>}
                    <div>
                        <label className="profile-upload">
                            <FileUploader
                                // hidden
                                accept="image/*"
                                name="avatar"
                                randomizeFilename
                                storageRef={this.props.firebase.storage().ref('')}
                                onUploadStart={this.handleUploadStart}
                                onUploadError={this.handleUploadError}
                                onUploadSuccess={this.handleUploadSuccess}
                                onProgress={this.handleProgress}
                            />
                        </label>
                    </div>   
                </div>
                { this.state.canSave ? <button className="profile-button" onClick={this.handleOnSave}>Save</button> : <button className="profile-button profile-button-inactive" onClick={this.handleOnSave}>Save</button>    }  
                <button className="profile-button" onClick={this.handleOnCancel}>Cancel</button>    
            </div>
        )
    }
}

function delay(t, v) {
    return new Promise(function(resolve) { 
      setTimeout(resolve.bind(null, v), t)
    });
  }
  
  function keepTrying(triesRemaining, storageRef) {
    if (triesRemaining < 0) {
      return Promise.reject('Server not responding');
    }
  
    return storageRef.getDownloadURL().then((url) => {
      return url;
    }).catch((error) => {
      switch (error.code) {
        case 'storage/object-not-found':
          return delay(1000).then(() => {
            return keepTrying(triesRemaining - 1, storageRef)
          });
        default:
          console.log(error);
          return Promise.reject(error);
      }
    })
  }


export default Profile